import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [2,6];

export const dictionary = {
		"/(app)": [~9,[2],[3]],
		"/(public)/auth": [~40,[5]],
		"/(public)/auth/account-infos": [~41,[5]],
		"/(public)/auth/email-verification": [~42,[5]],
		"/(public)/auth/phone-number-verification/[number]": [~43,[5]],
		"/(public)/auth/signIn": [44,[5]],
		"/(app)/calendar": [~12,[2],[3]],
		"/(app)/calendar/day": [13,[2],[3]],
		"/(app)/calendar/month": [~14,[2],[3]],
		"/(app)/calendar/week": [15,[2],[3]],
		"/(app)/collaborators": [~16,[2],[3]],
		"/(app)/collaborators/contracts": [~17,[2],[3]],
		"/(app)/collaborators/contracts/[id]": [~18,[2],[3]],
		"/(app)/collaborators/partners": [~19,[2],[3]],
		"/(app)/collaborators/partners/[id]": [~20,[2],[3]],
		"/(app)/debug": [~21,[2],[3]],
		"/(app)/management": [~22,[2],[3]],
		"/(app)/management/emergency-procedures": [~23,[2],[3]],
		"/(app)/management/exports": [24,[2],[3]],
		"/(app)/management/interventions": [~25,[2],[3]],
		"/(app)/management/interventions/create": [~27,[2],[3]],
		"/(app)/management/interventions/[id]": [~26,[2],[3]],
		"/(app)/management/obligations": [~28,[2],[3]],
		"/(app)/management/obligations/add": [~30,[2],[3]],
		"/(app)/management/obligations/add/[templateId]/[spaceId]": [~31,[2],[3]],
		"/(app)/management/obligations/[id]": [~29,[2],[3]],
		"/(app)/(admin)/obligation-templates": [~10,[2],[3]],
		"/(app)/(admin)/obligation-templates/create": [~11,[2],[3]],
		"/onboarding": [~45,[6]],
		"/onboarding/activity": [~46,[6],[,7]],
		"/onboarding/settings": [~47,[6],[,8]],
		"/onboarding/spaces": [48,[6]],
		"/(app)/property": [~32,[2],[3]],
		"/(app)/property/building-management": [~33,[2],[3]],
		"/(app)/property/objects": [~34,[2],[3]],
		"/(app)/property/objects/create": [~36,[2],[3]],
		"/(app)/property/objects/[id]": [~35,[2],[3]],
		"/(app)/property/spaces": [37,[2],[3]],
		"/signin": [49],
		"/signout": [50],
		"/(app)/users": [~38,[2],[3]],
		"/(app)/users/[id]": [~39,[2,4],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';